html,
body {
  background-color: #1b252f;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
.gradient {
  background: linear-gradient(105.09deg, #1e4850 10.39%, #151d26 101.17%);
}

body::-webkit-scrollbar {
  width: 6px;
  border-radius: 50%;
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #07bab6;
  outline: 1px solid #07bab6;
}

.bg-head {
  background-image: url("/src/assets/images/Background/Mintie.jpg");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.why-background {
  background-image: url("/src/assets/images/Background/Main\ bg.svg");
  background-position: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .canvasjs-chart-canvas {
  background-color: #1b252f !important;
} */
.inner {
  /* background: rgba(27, 37, 47, 0.05);
box-shadow: inset 0px 1px 2px rgba(255, 255, 255, 0.8), inset 0px 20px 40px rgba(255, 255, 255, 0.25), inset 0px -4px 4px rgba(255, 255, 255, 0.25), inset 0px 4px 12px rgba(255, 255, 255, 0.4), inset 0px -40px 40px #1B252F;
backdrop-filter: blur(40px); */
  /* Note: backdrop-filter has minimal browser support */
  background: transparent;
  box-shadow: inset 0px 1.07455px 2.14911px rgba(255, 255, 255, 0.8),
    inset 0px 21.4911px 42.9821px rgba(255, 255, 255, 0.25),
    inset 0px -4.29821px 4.29821px rgba(255, 255, 255, 0.25),
    inset 0px 4.29821px 12.8946px rgba(255, 255, 255, 0.4),
    inset 0px -42.9821px 42.9821px #1b252f;
  backdrop-filter: blur(42.9821px);
}

.teamgradient {
  background: linear-gradient(180deg, #38236c 0%, #091335 100%);
}

.footerbg {
  background-image: url("/src/assets/images/Background/Footer.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.canvasjs-chart-credit {
  display: none;
}
/* 
.splide__list {
  background-color: #1b252f;
  margin: 0 auto;
} */

.swiper-slide-active {
  filter: grayscale(0%) !important;
  transform: scale(1) !important;
}
.swiper-slide {
  filter: grayscale(100%);
  transform: scale(0.8);
}

.swiper-slide .caption {
  visibility: hidden;
}
.swiper-slide-active .caption {
  visibility: visible;
}

#flame1 {
  animation: flameDarker 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite
    alternate-reverse;
  -webkit-animation: flameDarker 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)
    infinite alternate-reverse;
}

#flame2 {
  animation: flamebright 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite
    alternate-reverse;
  -webkit-animation: flamebright 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67)
    infinite alternate-reverse;
}

#flame3 {
  animation: flamebrightest 0.375s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite
    alternate-reverse;
  -webkit-animation: flamebrightest 0.375s cubic-bezier(0.17, 0.67, 0.83, 0.67)
    infinite alternate-reverse;
}
@keyframes flamebrightest {
  0% {
    transform: scale(0.98, 1);
    fill: #fffefd;
  }
  100% {
    transform: scale(1.2, 1);
    fill: #ffcf546c;
    transform: translateX(5px);
  }
}

@keyframes flamebright {
  0% {
    transform: scale(0.98, 1);
    fill: #ffce54;
  }
  100% {
    transform: scale(1.2, 1);
    fill: #ffcf54bb;
    transform: translateX(5px);
  }
}

@keyframes flameDarker {
  0% {
    transform: scale(0.98, 1);
    fill: #f67542;
  }
  100% {
    transform: scale(1.7, 1);
    fill: #f6bb42;
  }
}
/* 
.rocket2 {
  animation: subtleVibrate 1s ease-in-out infinite alternate-reverse;
  -webkit-animation: subtleVibrate 1s ease-in-out infinite alternate-reverse;
}

@keyframes subtleVibrate {
  0% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(1px);
  }
} */
 